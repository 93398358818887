import { rem } from "@src/theme";

export default {
  heading: {
    variant: "typography.headingXLarge",
    mb: [rem(15), null, rem(40)],
    mt: [rem(10), null, rem(16)],
  },
  body: {
    pb: [rem(16), null, rem(160)],
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      pb: rem(16),
    },
    variant: "typography.bodyLarge",
    maxWidth: rem(550),
  },
  threeUpLabel: (position = "right") => ({
    color: "#000",
    variant: "typography.headingSmall",
    my: 0,
    position: "relative",
    mb: "10px",
    "&::before": {
      content: "''",
      width: 0,
      height: 0,
      borderLeft: "14px solid transparent",
      borderRight: "35px solid transparent",
      borderTop: "35px solid #f9f9f9",
      position: "absolute",
      top: `calc(${rem(34)} - 10px)`,
      left: position === "right" ? 0 : [0, null, "50%"],
      transform:
        position === "right"
          ? "translate(-9px, -1px) rotate(90deg)"
          : "translateY(-1px) rotate(90deg)",
    },
  }),
  threeUp: {
    minHeight: rem(128),
    display: "flex",
    alignItems: "center",
    mx: ["-16px", "-18px", 0],
    py: [rem(50)],
    px: ["16px", "18px", 0],
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      px: ["16px", "18px"],
    },
  },
  threeUpContainer: {
    mt: [0, null, rem(-113)],
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      mt: "1rem",
    },
    pt: [rem(25), null, 0],
  },
  threeUpColumn: (isLast, isFirst) => ({
    height: "100%",
    borderRight: isLast ? "none" : ["none", null, "1px solid"],
    borderBottom: isLast ? "none" : ["1px solid", null, "none"],
    pr: [0, "24px", null, "48px"],
    pt: isFirst ? [0] : [rem(23), null, 0],
    pb: [isLast ? 0 : rem(23), null, 0],
    borderColor: "coal",
  }),
  bgMask: (img: string) => ({
    display: ["none", null, "block"],
    width: "100%",
    minHeight: rem(450),
    backgroundImage: `url(${img})`,
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }),
};
