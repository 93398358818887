/** @jsx jsx */
import { jsx } from "theme-ui";
import SEO from "@components/seo";
import Layout from "@components/layouts";
import { graphql } from "gatsby";

import FeatureBlock from "@src/components/FeatureBlock";
import ArticleListingGrid from "@components/ArticleListingGrid";
import { Grid, Cell } from "@components/primitives/Grid";
import { useCraft } from "./query";
import { rem } from "@src/theme";

const SectionBlock = ({ type, ...props }) => {
  switch (type) {
    case "Craft_homepageSections_featureBlock_BlockType":
      return (
        <div className="section section--feature">
          {/* 
  // @ts-ignore */}
          <FeatureBlock {...props} />
        </div>
      );
    case "Craft_homepageSections_articleGridList_BlockType":
      return (
        <div className="section section--grid">
          <Grid>
            <Cell gridColumn={["1 / -1", null, "2 / -1"]}>
              {/* 
  // @ts-ignore */}
              <ArticleListingGrid {...props} />
            </Cell>
          </Grid>
        </div>
      );

    default:
      return null;
  }
};

export default ({ location }) => {
  const { sections } = useCraft();

  return (
    <Layout location={location}>
      <SEO location={location} metadata={null} />
      <div
        sx={{
          ".section:first-child": {
            mt: 0,
          },
          ".section:last-child": {
            mb: 0,
          },
          ".section": {
            my: rem(100),
          },
        }}
      >
        {sections && sections.length
          ? sections.map((section) => <SectionBlock {...section} />)
          : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query HomepageQuery {
    craft {
      entry(section: "home") {
        ...homeFields
      }
    }
  }
`;
