import striptags from "striptags";
const mapSections = ({ __typename, ...props }) => {
  const type = __typename;
  switch (type) {
    case "Craft_homepageSections_featureBlock_BlockType":
      const {
        positionVariant,
        article,
        threeupArticles,
        threeupCaption,
        threeupColour,
      } = props;
      const featuredArticle = article[0];
      if (featuredArticle) {
        return {
          type,
          positionVariant,
          article: {
            title: featuredArticle.title,
            heroImage: featuredArticle.heroImage[0]
              ? featuredArticle.heroImage[0].url
              : null,
            introText: featuredArticle.introductoryText,
            tagDuo: [
              featuredArticle.contentType[0].title,
              featuredArticle.primaryTopic[0].title,
            ],
            url: `/articles/${featuredArticle.slug}`,
          },
          threeupArticles,
          threeupCaption,
          threeupColour,
        };
      } else {
        return null;
      }

    case "Craft_homepageSections_articleGridList_BlockType":
      return {
        type,
        featureTag: props.featureTag[0] || null,
        items: props.articles.map((article) => ({
          title: article.title,
          img: article.thumbnail[0]
            ? article.thumbnail[0].url
            : article.heroImage[0]
            ? article.heroImage[0].url
            : null,
          introText: article.introductoryText
            ? striptags(article.introductoryText)
            : null,
          url: `/articles/${article.slug}`,
          tagDuo: [article.contentType[0].title, article.primaryTopic[0].title],
        })),
      };

    default:
      return false;
  }
};

export const mapCraft = (craft) => {
  return {
    ...craft,
    sections: craft.entry.homepageSections
      ? craft.entry.homepageSections.map(mapSections).filter(Boolean)
      : null,
  };
};
