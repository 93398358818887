/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import React from "react";
import { Grid, Cell } from "@components/primitives/Grid";
import { rem } from "@src/theme";
import { Link } from "gatsby";
import styles from "./styles";
import striptags from "striptags";
import TagDuo from "@components/TagDuo";
import ReadMore from "@components/ReadMore";
interface ComponentProps {
  positionVariant?: string;
  article?: {
    title: string;
    url: string;
    heroImage: string;
    tagDuo: Array<string>;
    introText: string;
  };
  threeupCaption: string;
  threeupColour: string;
  threeupArticles: Array<{
    title: string;
    slug: string;
    primaryTopic: Array<any>;
  }>;
}
const FeatureBlock = ({
  positionVariant,
  article,
  threeupArticles,
  threeupCaption,
  threeupColour,
}: ComponentProps) => {
  const Head = () => (
    <React.Fragment>
      <div
        sx={{
          mt: [rem(15), null, rem(48)],
        }}
      >
        <TagDuo items={article.tagDuo} />
      </div>

      <Styled.h1 sx={styles.heading}>
        <Link
          to={article.url}
          sx={{
            textDecoration: "none",
            color: "inherit",
            "&:hover": {
              bg: "transparent",
            },
          }}
        >
          {article.title}
        </Link>
      </Styled.h1>
      <p sx={styles.body}>
        {striptags(article.introText)}
        <ReadMore url={article.url} />
      </p>
    </React.Fragment>
  );

  const Image = () => (
    <React.Fragment>
      <img
        src={article.heroImage}
        sx={{
          width: "100%",
          display: ["block", null, "none"],
        }}
      ></img>
      <div sx={styles.bgMask(article.heroImage)}></div>
    </React.Fragment>
  );

  const ThreeUpColumns = () => (
    <React.Fragment>
      {threeupArticles.map(({ title, slug, primaryTopic }, index) => (
        <Cell gridColumn={["1/  -1 ", null, "span 4"]}>
          <div
            sx={{
              ...styles.threeUpColumn(
                index + 1 === threeupArticles.length && index !== 0,
                index === 0
              ),
            }}
          >
            <p
              sx={{
                variant: "typography.headingSmall",
                my: 0,
                mb: rem(4),
              }}
            >
              {primaryTopic[0].title}
            </p>
            <Styled.h3
              as={Link}
              // @ts-ignore
              to={`/articles/${slug}`}
              sx={{
                my: 0,
                color: "char",
              }}
            >
              {title}
            </Styled.h3>
          </div>
        </Cell>
      ))}
    </React.Fragment>
  );

  return positionVariant === "right" ? (
    <section>
      <Grid>
        <Cell
          gridRow={["2", null, "1"]}
          gridColumn={["1 / -1", null, "2 / span 9"]}
        >
          <Head />
        </Cell>
        <Cell gridRow={["1", "1"]} gridColumn={["1 / -1", null, "11 / span 6"]}>
          <Image />
        </Cell>
      </Grid>
      {threeupArticles.length ? (
        <div sx={styles.threeUpContainer}>
          <Grid>
            <Cell gridColumn={["1 / -1", null, "1 / span 14"]}>
              <Grid
                gridTemplateColumns={[
                  "repeat(4, 1fr)",
                  "repeat(16, 1fr)",
                  "repeat(14, 1fr)",
                ]}
              >
                <Cell gridColumn={["1/ -1", null, "2 / span 12"]}>
                  <Grid
                    gridTemplateColumns={[
                      "repeat(4, 1fr)",
                      "repeat(16, 1fr)",
                      "repeat(12, 1fr)",
                    ]}
                  >
                    <Cell gridColumn={["1/ -1", null, "1 / span 12"]}>
                      <p
                        sx={{
                          ...styles.threeUpLabel(),
                        }}
                      >
                        {threeupCaption}
                      </p>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>

            <Cell gridColumn={["1 / -1", null, "1 / span 14"]}>
              <div sx={{ bg: threeupColour, ...styles.threeUp }}>
                <Grid
                  gridTemplateColumns={[
                    "repeat(4, 1fr)",
                    "repeat(16, 1fr)",
                    "repeat(14, 1fr)",
                  ]}
                  styles={{
                    width: "100%",
                  }}
                >
                  <Cell gridColumn={["1/ -1", null, "2 / span 12"]}>
                    <Grid
                      gridTemplateColumns={[
                        "repeat(4, 1fr)",
                        "repeat(16, 1fr)",
                        "repeat(12, 1fr)",
                      ]}
                      styles={{
                        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
                          display: "flex!important",
                          justifyContent: "space-around!important",
                        },
                      }}
                    >
                      <ThreeUpColumns />
                    </Grid>
                  </Cell>
                </Grid>
              </div>
            </Cell>
          </Grid>
        </div>
      ) : null}
    </section>
  ) : (
    <section>
      <Grid>
        <Cell gridRow={["1", "1"]} gridColumn={["1 / -1", null, "1 / span 6"]}>
          <Image />
        </Cell>
        <Cell
          gridRow={["2", null, "1"]}
          gridColumn={["1 / -1", null, "8 / -1"]}
        >
          <Head />
        </Cell>
      </Grid>
      {threeupArticles.length ? (
        <div sx={styles.threeUpContainer}>
          <Grid>
            <Cell gridColumn={["1 / -1", null, "1 / - 1"]}>
              <Grid
                gridTemplateColumns={[
                  "repeat(4, 1fr)",
                  "repeat(16, 1fr)",
                  "repeat(14, 1fr)",
                ]}
              >
                <Cell gridColumn={["1/ -1", null, "2 / -1"]}>
                  <Grid
                    gridTemplateColumns={["repeat(4, 1fr)", "repeat(16, 1fr)"]}
                  >
                    <Cell gridColumn={["1/ -1", null, "-1"]}>
                      <p sx={styles.threeUpLabel("left")}>{threeupCaption}</p>
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>

            <Cell gridColumn={["1 / -1", null, "3 / -1"]}>
              <div
                sx={{
                  ...styles.threeUp,
                  bg: threeupColour,
                }}
              >
                <Grid
                  gridTemplateColumns={[
                    "repeat(4, 1fr)",
                    "repeat(16, 1fr)",
                    "repeat(14, 1fr)",
                  ]}
                  styles={{
                    width: "100%",
                  }}
                >
                  <Cell gridColumn={["1/ -1", null, "2 / span 12"]}>
                    <Grid
                      gridTemplateColumns={[
                        "repeat(4, 1fr)",
                        "repeat(16, 1fr)",
                        "repeat(12, 1fr)",
                      ]}
                      styles={{
                        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
                          display: "flex!important",
                          justifyContent: "space-around!important",
                        },
                      }}
                    >
                      <ThreeUpColumns />
                    </Grid>
                  </Cell>
                </Grid>
              </div>
            </Cell>
          </Grid>
        </div>
      ) : null}
    </section>
  );
};

export default FeatureBlock;
