import { useStaticQuery, graphql } from "gatsby";
import { mapCraft } from "./mappings";

const query = graphql`
  {
    craft {
      entry(section: "home") {
        ...homeFields
      }
    }
  }
`;

export const useCraft = () => {
  const { craft } = useStaticQuery(query);
  return mapCraft(craft);
};
